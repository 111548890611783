import classnames from 'classnames'
import PropTypes from 'prop-types'

import CloseIcon from '../svg_icons/icons/CloseIcon'

import './closer.scss'

const Closer = ({className, closer, ...otherProps}) => (
  <CloseIcon
    className={classnames('core closer', className)}
    data-testid='closer'
    onClick={closer} {...otherProps}
  />
)

Closer.defaultProps = {alt: 'Close modal'}

Closer.propTypes = {
  alt: PropTypes.string,
  closer: PropTypes.func
}

export default Closer
